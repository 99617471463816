<template>
  <div class="hotel-editor">
    <v-text-field v-model="newHotel.name" label="Hotel Name" />
    <v-text-field v-model="newHotel.address.line1" label="Address Line 1" />
    <v-text-field v-model="newHotel.address.line2" label="Address Line 2" />
    <v-text-field v-model="newHotel.address.city" label="City"></v-text-field>
    <v-select
      v-model="newHotel.address.state"
      label="State"
      :items="states"
    />
    <v-text-field v-model="newHotel.address.zip" label="Zip Code" />
  </div>
</template>

<script>
export default {
  name: 'HotelEditor',
  data: () => ({
    newHotel: {
      address: {
        state: {
          text: 'Louisiana',
          value: 'LA'
        }
      }
    },
    states: [
      {
        text: 'Louisiana',
        value: 'LA'
      }
    ]
  }),

  watch: {
    newHotel: {
      handler (newValue) {
        this.$emit('input', newValue)
      },
      deep: true
    }
  }
}
</script>

<style>

</style>
