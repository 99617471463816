<template>
  <v-container>
    <h1 class="mb-5">Admin</h1>

    <v-form class="pb-10">
      <v-row>
        <v-col>
          <h2>Festival Dates</h2>
          <h1 v-if="globalSettings && globalSettings.festivalDates">
            {{ festivalStart }} to {{ festivalEnd }}
          </h1>
          <v-date-picker
            v-if="globalSettings"
            v-model="globalSettings.festivalDates"
            range
            full-width
          />
        </v-col>
      </v-row>
      <v-btn large class="accent" @click="updateFestivalDates">Save Dates</v-btn>
    </v-form>

    <v-form class="pb-10">
      <v-card>
        <v-card-title>Assign Admin</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="newAdminEmail"
            label="Email"
            type="email"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="assignAdmin" large color="accent">Assign</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

    <v-form>
      <v-card>
        <v-card-title>Support Email</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="globalSettings.supportEmail"
            label="Email"
            type="email"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="updateSupportEmail" large color="accent">Set Support Email</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

    <v-form class="py-10">
      <v-card>
        <v-card-title class="primary white--text">
          <h2>New Author</h2>
        </v-card-title>
        <v-card-text>
          <AuthorEditorFields v-model="newAuthor" ref="newAuthorFields" />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="addNewAuthor" large color="accent">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

    <TheAuthorsTable />

    <v-form class="py-10">
      <v-card>
        <v-card-title class="primary white--text">
          <h2>New Event Location</h2>
        </v-card-title>
        <v-card-text>
          <v-textarea v-model="newLocation" label="Location details" />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="addNewLocation" large color="accent">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

    <h2>Existing Locations</h2>
    <TheLocations />

    <v-form class="py-10">
      <v-card>
        <v-card-title class="primary white--text">
          <h2>New Hotel</h2>
        </v-card-title>
        <v-card-text>
          <HotelEditor v-model="newHotel" />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="addNewHotel" large color="accent">
            Submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

    <h2>Existing Hotels</h2>
    <TheHotels />

    <h2>Developer</h2>
    <v-btn v-if="!showingDeveloperFunctions" color="error" @click="showingDeveloperFunctions = true">Show Functions</v-btn>
    <v-btn v-if="showingDeveloperFunctions" color="primary" @click="showingDeveloperFunctions = false">Hide Functions</v-btn>
    <br><br>
    <template v-if="showingDeveloperFunctions">
      <v-btn color="error" @click="clearNewlines">Clear \n from Author Names</v-btn>
    </template>
  </v-container>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { collection, addDoc, setDoc, updateDoc, doc } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import AuthorEditorFields from '@/components/AuthorEditorFields'
import HotelEditor from '@/components/HotelEditor'
import TheAuthorsTable from '@/components/TheAuthorsTable'
import TheLocations from '@/components/TheLocations'
import TheHotels from '@/components/TheHotels'

export default {
  components: {
    AuthorEditorFields,
    HotelEditor,
    TheAuthorsTable,
    TheLocations,
    TheHotels
  },
  async created () {
    this.globalFlagsListener()
    await this.authorsListener()
  },
  data: () => ({
    newAdminEmail: null,
    newEvent: {},
    newAuthor: {},
    newHotel: {},
    newLocation: '',
    showingDeveloperFunctions: false
  }),
  computed: {
    ...mapState(['firestore', 'fbFunctions', 'globalSettings']),
    ...mapGetters(['allAuthors', 'allLocations']),
    festivalStart () {
      return this.globalSettings?.festivalDates?.length ? this.formatIsoDate(this.globalSettings.festivalDates[0]) : ''
    },
    festivalEnd () {
      return this.globalSettings?.festivalDates?.length ? this.formatIsoDate(this.globalSettings.festivalDates[this.globalSettings.festivalDates.length - 1]) : ''
    }
  },
  methods: {
    ...mapMutations(['setGlobalNotification']),
    ...mapActions(['authorsListener', 'globalFlagsListener']),

    async updateFestivalDates () {
      console.log('Updating festival dates:', this.globalSettings.festivalDates)
      try {
        await setDoc(doc(this.firestore, 'settings', 'global'), {
          festivalDates: this.globalSettings.festivalDates
        }, { merge: true })
        this.setGlobalNotification({ val: 'Updated festival dates.' })
      } catch (err) {
        console.log(err)
        this.setGlobalNotification({ val: 'Error updating festival dates.', type: 'error' })
      }
    },

    /**
     * Assigns Firebase admin privileges to the target email address.
     */
    async assignAdmin () {
      console.log('Assigning admin privileges to', this.newAdminEmail)
      try {
        await httpsCallable(this.fbFunctions, 'assignAdmin')(this.newAdminEmail)
        this.setGlobalNotification(`Assigned admin privileges to ${this.newAdminEmail}`)
      } catch (err) {
        console.log(err)
        this.setGlobalNotification({ val: err.message, type: 'error' })
      }
    },

    async updateSupportEmail () {
      console.log('Updating support email:', this.globalSettings.supportEmail)
      if (!this.globalSettings.supportEmail) {
        this.setGlobalNotification({ val: 'Please enter a valid email address.', type: 'error' })
        return
      }
      try {
        await setDoc(doc(this.firestore, 'settings', 'global'), {
          supportEmail: this.globalSettings.supportEmail
        }, { merge: true })
        this.setGlobalNotification({ val: 'Updated support email.' })
      } catch (err) {
        console.log(err)
        this.setGlobalNotification({ val: 'Error updating festival dates.', type: 'error' })
      }
    },

    /**
     * Add a new author to the 'authors' Firestore collection
     */
    async addNewAuthor () {
      try {
        await addDoc(collection(this.firestore, 'authors'), this.newAuthor)
        this.$refs.newAuthorFields.clear()
        this.setGlobalNotification({ val: 'Added new author.' })
      } catch (err) {
        console.log(err)
        this.setGlobalNotification({ val: 'Error adding new author.', type: 'error' })
      }
    },

    /**
     * Add a new hotel to the 'locations' Firestore collection
     * These locations can be attached to events
     */
    async addNewLocation () {
      try {
        await addDoc(collection(this.firestore, 'locations'), { name: this.newLocation, order: this.allLocations.length })
        this.newLocation = ''
        this.setGlobalNotification({ val: 'Added new location.' })
      } catch (err) {
        console.log(err)
        this.setGlobalNotification({ val: 'Error adding new location.', type: 'error' })
      }
    },

    /**
     * Add a new hotel to the 'hotels' Firestore collection
     */
    async addNewHotel () {
      try {
        await addDoc(collection(this.firestore, 'hotels'), this.newHotel)
        Object.assign(this.newHotel, {})
        this.setGlobalNotification({ val: 'Added new hotel.' })
      } catch (err) {
        console.log(err)
        this.setGlobalNotification({ val: 'Error adding new hotel.', type: 'error' })
      }
    },

    /**
     * This utility function loops through all of the authors pulled from Firestore
     * and removes any newlines or extra whitespace from their names, which might exist
     * if the data was imported from a CSV.
     */
    async clearNewlines () {
      if (!confirm('Are you sure you want to clear newlines from author names? This cannot be undone.')) return
      const authors = this.allAuthors
      for (let i = 0; i < authors.length; i++) {
        const author = authors[i]
        const authorBefore = JSON.parse(JSON.stringify(author))
        author.firstName = author.firstName?.replaceAll('\n', '').trim()
        author.lastName = author.lastName?.replaceAll('\n', '').trim()
        author.fullName = `${author.firstName} ${author.lastName}`

        const nameData = {
          firstName: author.firstName || 'FirstName',
          lastName: author.lastName || 'LastName',
          fullName: author.fullName || 'FirstName LastName'
        }
        await updateDoc(doc(this.firestore, 'authors', author.id), nameData)

        if (authorBefore.fullName !== author.fullName || authorBefore.firstName !== author.firstName || authorBefore.lastName !== author.lastName) {
          console.log('Updated', author.fullName)
        }
      }
      this.setGlobalNotification({ val: 'Cleared newlines from author names.' })
    }
  }
}
</script>

<style>

</style>
